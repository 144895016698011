<template>
  <div class="tco-rules-container">
    <div class="section mt-5">
      <CategoryTitle :category="category" />

      <!-- *** regolamento del 30 novembre 2022 -> ora inserito in category title and description *** -->

      <!-- <h1>Regolamento</h1> -->

      <!-- <div class="tco-section-title">
        CONDIZIONI GENERALI PER IL RILASCIO DI TIGROS CARTA ORO E REGOLAMENTO DI
        UTILIZZO
      </div>

      <ol class="list">
        <li>TIGROS CARTA ORO</li>
        <p>
          è la carta fedeltà che consente di accedere, sussistendo i requisiti
          indicati nel presente regolamento, a particolari vantaggi, promozioni
          e campagne dedicate, organizzate a discrezione di TIGROS S.p.A.
        </p>
        <li>RILASCIO</li>
        <p>
          La TIGROS CARTA ORO sarà rilasciata a titolo gratuito ai clienti già
          titolari di TIGROS Card che nei 12 mesi anteriori al rilascio abbiano
          effettuato acquisti per un importo uguale o superiore ad Euro 5.000,00
          (soglia di spesa) nell’ambito di un normale consumo familiare.
        </p>
        <p>Non hanno diritto di ricevere la TIGROS CARTA ORO:</p>
        <ul class="sub-list">
          <li>
            coloro che effettuano acquisti nell’ambito della propria attività
            professionale ed in generale i titolari di TIGROS Card alla quale
            risulti abbinata una partita iva;
          </li>
          <li>i lavoratori dipendenti di TIGROS S.p.A. e Tre M S.r.l.;</li>
          <li>
            coloro ai quali risultino applicate specifiche e dedicate
            convenzioni, promozioni ed agevolazioni e comunque che siano
            titolari di TIGROS Card Convenzionate;
          </li>
          <li>
            i clienti con comportamento di spesa non compatibile con un normale
            consumo familiare, come disciplinato dall’art. 3 del presente
            contratto.
          </li>
        </ul>
        <li>
          VALUTAZIONE DEL COMPORTAMENTO DI SPESA NON COMPATIBILE CON IL NORMALE
          CONSUMO FAMILIARE
        </li>
        <p>
          TIGROS S.p.A. si riserva il diritto di non rilasciare, ovvero di
          sospendere/revocare, la TIGROS CARTA ORO ai clienti che effettuino
          acquisti ritenuti non compatibili con un normale consumo familiare,
          secondo i parametri indicati al capoverso che segue. A mero titolo
          esemplificativo, si ritengono non compatibili con il normale consumo
          familiare acquisti che, in un arco temporale di 12 mesi, risultino
          superiori ad Euro 15.000,00, ovvero siano effettuati con l’utilizzo di
          Ticket di qualunque genere – c.d. buoni pasto - per un importo che
          ecceda Euro 3.600,00. In ogni caso, la valutazione in merito alla
          permanenza dei requisiti sarà effettuata da TIGROS S.p.A. a suo
          insindacabile giudizio.
        </p>
        <li>MODALITA’ DI RILASCIO</li>
        <p>
          I clienti in possesso dei requisiti di cui al punto 2 del presente
          regolamento riceveranno la TIGROS CARTA ORO, in duplice copia,
          direttamente all’indirizzo postale indicato dal cliente al momento
          della sottoscrizione di TIGROS Card, salvo preferenza espressa di
          riceverla in formato digitale. L’invio della TIGROS CARTA ORO sarà
          possibile solamente nel caso in cui i dati forniti all’atto di
          adesione a TIGROS Card siano aggiornati ed il cliente abbia
          acconsentito al trattamento dei dati personali per finalità di
          marketing e di analisi dei dati e profilazione, in quanto necessaria
          alla verifica della sussistenza dei requisiti per il rilascio.
        </p>
        <p>
          L’utilizzo della TIGROS CARTA ORO comporterà l’accettazione integrale
          del presente regolamento che sarà sempre consultabile nell’area
          riservata del cliente sul sito tigros.it
        </p>
        <li>UTILIZZO DELLA TIGROS CARTA ORO</li>
        <p>La TIGROS CARTA ORO è personale e non cedibile.</p>
        <p>
          Il titolare, richiedendo l’attivazione della carta fedeltà, si impegna
          a rispettare il presente regolamento.
        </p>
        <li>VALIDITA'</li>
        <ul class="sub-list" type="a">
          <p>
            Ai fini del rinnovo della validità della TIGROS CARTA ORO il
            titolare dovrà effettuare entro l’anno solare di rilascio - ovvero
            nei 12 mesi successivi al rilascio acquisti per un importo uguale o
            superiore ad Euro 5.000,00 (soglia di spesa) nell’ambito di un
            normale consumo familiare.
          </p>
          <li>
            In caso di mancato rispetto dei requisiti indicati dal capoverso
            precedente e/o di sopravvenienza di uno dei motivi ostativi indicati
            dall’art. 2, il titolare riconosce espressamente che sarà facoltà di
            TIGROS S.p.A. disattivare tutti i vantaggi legati alla TIGROS CARTA
            ORO.
          </li>
          <li>
            Ugualmente tutti i vantaggi legati alla TIGROS CARTA ORO saranno
            disattivati nel caso in cui il cliente revochi il consenso
            precedentemente prestato al trattamento dei dati personali per
            finalità di marketing e di analisi dei dati e profilazione, in
            quanto necessari per la verifica della permanenza dei requisiti.
          </li>
          <li>
            Il Cliente può rinunciare in qualsiasi momento alla TIGROS CARTA ORO
            già attivata con la semplice restituzione della stessa in un
            qualsiasi punto vendita TIGROS.
          </li>
          <li>
            TIGROS S.p.A. si riserva, in qualsiasi momento, la facoltà di
            interrompere, sospendere o modificare il servizio e/o le condizioni
            che lo regolano, senza assumersi responsabilità per eventuali
            interruzioni dello stesso, anche ove le stesse non fossero
            comunicate al cliente. Le comunicazioni saranno effettuate tramite
            email, oppure sms, posta e/o nell’area riservata sul sito tigros.it.
          </li>
          <li>
            TIGROS S.p.A. si riserva in ogni caso il diritto di sospendere o
            revocare la TIGROS CARTA ORO nel caso di utilizzo non conforme al
            presente regolamento e comunque contrario a correttezza e buona
            fede.
          </li>
        </ul>
        <li>SMARRIMENTO</li>
        <p>
          In caso di smarrimento o furto, il titolare della TIGROS CARTA ORO si
          impegna a comunicare tempestivamente l’accaduto recandosi al box
          informazioni di qualsiasi punto vendita TIGROS S.p.A. oppure
          contattando il servizio clienti al numero 800.90.50.33. Fino a tale
          momento TIGROS S.p.A. non si assumerà alcuna responsabilità in merito
          all’utilizzo improprio della carta.
        </p>
        <li>VANTAGGI</li>
        <p>
          La TIGROS CARTA ORO consente di accedere a sconti, vantaggi e in
          generale a campagne dedicate organizzate a discrezione di TIGROS
          S.p.A.
        </p>
        <p>
          Le promozioni riservate ai titolari della TIGROS CARTA ORO saranno
          comunicate al titolare all’indirizzo email e/o sms, indirizzo postale
          e sempre consultabili nell’area riservata.
        </p>
        <li>TUTELA E RISERVATEZZA DEI DATI PERSONALI</li>
        <p>
          Il trattamento dei Suoi dati personali avverrà nel rispetto della
          Normativa Applicabile, come specificato nell’informativa completa
          TIGROS Card disponibile sul sito, e delle condizioni specificate nel
          Regolamento sopra riportato.
        </p>
      </ol> -->
    </div>
  </div>
</template>
<style lang="scss">
.tco-rules-container {
  font-weight: bold;
  h1 {
    text-align: center;
  }
  .section {
    padding: 40px 140px;
    border: 1px solid #c7c3e7;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 20px;
    }
  }
  .tco-section-title {
    margin-bottom: 20px;
  }
  p {
    font-weight: normal;
  }
  ul.sub-list {
    li {
      list-style-type: circle !important;
    }
  }
  .sub-list {
    padding-left: 0px;
    font-weight: normal;
    margin: 20px 0;
    li {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }
  .list {
    padding-left: 20px;
    li + p {
      margin-top: 20px;
    }
    p {
      margin-left: -20px;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "Rules",
  mixins: [categoryMixins],
  components: {
    CategoryTitle
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {}
};
</script>
